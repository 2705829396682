import moment from "moment/moment";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            selected: [],
            selectedSource: 'selected',
            allItems: [],
            tableOptions: {
                currentPage: 1,
                perPage: 20,
                totalRows: 0,
                grandTotal: {},
                pageOptions: [20, 50, 100, 500],
                sortBy: 'id',
                sortDesc: true,
            },
            fields: [],
            params: {},
            savedFilters: [],
            saveFilters: false,
            filtersApplied: false,
            useSavedFilters: false,
            filter: {},
            firstLoaded: true,
            tableUniqKey: null,
        }
    },
    methods: {
        selectRow(val, item) {
            if (val) {
                this.selected.push(item)
            } else {
                this.selected = this.selected.filter((row) => {
                    return row.id !== item.id;
                });
                this.selectedSource = 'selected'
            }
        },
        selectAll(val) {
            if (val) {
                this.selected = this.allItems.filter(item => {
                    return typeof item.selectable == 'undefined' || item.selectable
                })
            } else {
                this.selected = []
            }
        },
        selectSource(val) {
            this.selectedSource = val;
        },
        refreshTable() {
            this.selected = []
            this.selectedSource = 'selected'
            this.$refs.table.refresh()
        },
        changeBaseFilter(value) {
            if (this.saveFilters) {
                value.filterChanged = true
            }

            this.filter = JSON.parse(JSON.stringify(value))
        },
        getSelectedData(table) {
            let data = {
                type: table,
                filter: this.filter,
                page: this.tableOptions.currentPage,
                per_page: this.tableOptions.perPage,
                sorting_by: this.tableOptions.sortBy,
                sorting_desc: this.tableOptions.sortDesc ? 'true' : 'false',
                additionalOptions: this.additionalOptions,
                ids: this.selected.map(row => row.id),
                source: this.selectedSource,
                selectedSource: this.selectedSource,
                export: {
                    ids: this.selected.map(row => row.id),
                    source: this.selectedSource,
                }
            }

            let baseParams = this.ctxBaseParams()
            Object.keys(baseParams).forEach(key => {
                data[key] = baseParams[key]
            })

            let additionalParams = this.ctxAdditionalParams()
            Object.keys(additionalParams).forEach(key => {
                data.filter[key] = additionalParams[key]
            })

            return data
        },
        ctxBaseParams() {
            return {}
        },
        ctxAdditionalParams() {
            return {}
        },
        commonProviderFetch(ctx, dispatchName, getFunctionName, assignCustomParams = {}, callback = null) {
            if (this.filtersApplied) {
                this.filtersApplied = false
                return null
            }

            let baseParams = this.ctxBaseParams()
            Object.keys(baseParams).forEach(key => {
                ctx[key] = baseParams[key]
            })

            let additionalParams = this.ctxAdditionalParams()
            Object.keys(additionalParams).forEach(key => {
                ctx.filter[key] = additionalParams[key]
            })

            ctx.filter.tableUniqKey = this.tableUniqKey

            return this.$store.dispatch(dispatchName, ctx).then(() => {
                return this.commonAfterTableRequest(getFunctionName, assignCustomParams, callback)
            })
        },
        commonAfterTableRequest(getFunctionName, assignCustomParams = {}, callback = null) {
            const tableData = typeof getFunctionName === 'string' ? this[getFunctionName]() : getFunctionName;

            this.tableOptions.totalRows = tableData.count
            this.tableOptions.grandTotal = tableData.totalData

            this.fields = tableData.columns
            this.allItems = tableData.data
            this.savedFilters = tableData.savedFilters

            if ((tableData.params && (this.saveFilters || this.useSavedFilters) && this.firstLoaded)) {
                if (typeof tableData.params.filter != 'undefined') {
                    this.filter = Array.isArray(tableData.params.filter) ? {} : tableData.params.filter
                    this.filtersApplied = true
                }
                if (typeof tableData.params.per_page != 'undefined') {
                    this.tableOptions.perPage = tableData.params.per_page
                    this.filtersApplied = true
                }
            }

            Object.keys(assignCustomParams).forEach(key => {
                this[key] = tableData[assignCustomParams[key]]
            })

            if (tableData.params && typeof tableData.params.filter != 'undefined' && tableData.params.filter.created_at) {
                const dates = tableData.params.filter.created_at.split(';')
                this.month = moment(dates[0], 'DD.MM.YYYY').format('MM.YYYY')
            }

            if (callback) {
                callback(tableData);
            }

            this.firstLoaded = false

            return tableData.data
        }
    },
}